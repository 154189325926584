<template>
    <div class="sel-company-card">
        <van-row @click="pageTo(role.to)" class="card">
            <van-col span="10">
                <van-image class="portrait"
                           round
                           lazy-load
                           fit="cover"
                           width="70"
                           height="70"
                           :src="require('@/assets/'+role.portrait+'')"
                />
            </van-col>
            <van-col span="10">
                <van-row class="role-name">{{role.roleName}}</van-row>
            </van-col>
            <van-col span="3" offset="1">
                <van-icon name="arrow" class="icon" color="#C0BFCE"/>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Row, Col, Image, Icon, Lazyload} from 'vant'

    Vue.use(Row).use(Col).use(Image).use(Icon).use(Lazyload)
    export default {
        name: "index",
        props: {
            role: {type: Object}
        },
        methods: {
            pageTo(data) {
                this.$emit('pageTo', data)
            }
        }
    }
</script>

<style scoped>
    div.sel-company-card {
        margin-top: 30px;
        padding: 0 10px;
    }

    .sel-company-card .card {
        background: #ececec;
        height: 116px;
        border-radius: 6px;
    }

    .sel-company-card .card .van-col {
        height: 100%;
        position: relative;
    }

    .sel-company-card .card .portrait {
        position: absolute;
        bottom: 50%;
        left: 0;
        transform: translate(50%, 50%);
    }

    .sel-company-card .role-name {
        font-size: 22px;
        font-weight: 700;
        color: #101010;
        font-style: normal;

        position: absolute;
        top: 25%;
        left: 0;
        transform: translate(0, 50%);
    }

    .sel-company-card .introduction {
        font-size: 14px;
        font-weight: 400;
        color: #101010;
        font-style: normal;

        position: absolute;
        bottom: 40%;
        left: 0;
        transform: translate(0, 50%);
    }

    .sel-company-card .icon {
        position: absolute;
        bottom: 50%;
        left: 0;
        transform: translate(0, 50%);
    }
</style>
