<template>
  <div class="sel-company">
    <div class="title">请选择类型</div>
    <sel-company-card v-for="item in this.companyTypes" v-bind:key="item.id" :role="item"
                      @pageTo="pageTo"></sel-company-card>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import SelCompanyCard from "@/components/SelCompany/index"
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "BulkCrmsSelCompany",
  components: {CrmTabBar, SelCompanyCard},
  data() {
    return {
      companyTypes: [
        {
          id: '0',
          roleName: '已有客户',
          to: 'BulkCrmsVisitTemporaryExistList',
          portrait: '已有客户.jpg',
        },
        {
          id: '1',
          roleName: '新增客户',
          to: 'BulkCrmsVisitTemporaryNewList',
          portrait: '新增客户.jpg',
        }
      ]
    }
  },
  created() {
  },
  methods: {
    pageTo(to) {
      this.$router.push({path: to })
    },
  }
}
</script>

<style scoped>
.sel-company{
  background: white;
}

.sel-company .title {
  margin-top: 60px;
  font-size: 24px;
  color: #101010;
  text-align: center;
}
</style>